import React from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon'
import { Select } from "@chakra-ui/react"

const SelectComponent = ({ children, theme, ...props }) => (
  <div className={theme['next-pagination__select']}>
    <Select {...props}>{children}</Select>
    {/* <span className={theme['next-pagination__select-suffix']}> */}
      {/* <Icon icon='expand-more' /> */}
    {/* </span> */}
  </div>
)

SelectComponent.propTypes = {
  theme: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

export default SelectComponent
